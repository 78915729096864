import React, { useEffect, useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { useQuery, useMutation } from "@tanstack/react-query";
import { Tooltip } from "@mui/material";
import { CgUserList } from "react-icons/cg";
import {
  MdBusinessCenter,
  MdOutlineDashboard,
  MdOutlineInventory2,
  MdOutlineReceiptLong,
} from "react-icons/md";
import {
  BsBoxSeam,
  BsCartPlus,
  BsChatLeftText,
  BsPeopleFill,
} from "react-icons/bs";
import { BiPurchaseTag } from "react-icons/bi";
import { RiArrowGoBackLine, RiFileChartLine } from "react-icons/ri";
import { FiSettings, FiLogOut } from "react-icons/fi";
import { GrNotes } from "react-icons/gr";
import { IoIosCreate } from "react-icons/io";
import { TbDiscount, TbFileImport } from "react-icons/tb";
import {
  FaBars,
  FaUserCog,
  FaHandHoldingUsd,
  FaFileInvoiceDollar,
} from "react-icons/fa";
import Logo from "../../assets/icons/logoOlin.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/features/auth/authSlice";
import { getUserProfile } from "../../services/authApi";
import { checkStatusByEmail } from "../../services/authApi";
import { getOutletList } from "../../services/outletApi";
import SwitchOutletModal from "../common/particles.jsx/SwitchOutletModal";
import {
  INVENTORY_ROLE,
  INVOICE_ROLE,
  PATIENT_CARE_ROLE,
  RETUR_ROLE,
  SIDEBAR_WIDTH,
  WAREHOUSE_INVENTORY_ROLE,
  WAREHOUSE_INVOICE_ROLE,
  WAREHOUSE_ROLE,
} from "../constants/constant";

const SidebarComponents = () => {
  const { toggleSidebar, broken } = useProSidebar();
  const [selectedOutlet, setSelectedOutlet] = useState(
    Number(localStorage.getItem("company_id"))
  );

  const [selectedOutletName, setSelectedOutletName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [userRole, setUserRole] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const img_profile = localStorage.getItem("img_profile");
  const name = localStorage.getItem("name");
  const job = localStorage.getItem("job");
  const titleId = localStorage.getItem("title");

  const { data: profileData } = useQuery({
    queryKey: ["profileData"],
    queryFn: () => getUserProfile(),
  });

  const { mutate: mutateCheckStatusByEmail, data: statusUser } = useMutation({
    mutationFn: checkStatusByEmail,
  });

  const { data: dataOutlet } = useQuery({
    queryFn: () => getOutletList(),
    queryKey: ["outlets"],
  });

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  useEffect(() => {
    if (profileData === undefined) return;
    mutateCheckStatusByEmail({ email: profileData?.email });
  }, [profileData]);

  useEffect(() => {
    if (
      dataOutlet &&
      localStorage.getItem("company_id") &&
      selectedOutlet !== 0 &&
      selectedOutlet !== Number(localStorage.getItem("company_id"))
    )
      setOpenModal(true);
    setSelectedOutletName(
      dataOutlet?.find((item) => item.company_id === Number(selectedOutlet))
        ?.company_name
    );
  }, [selectedOutlet]);

  useEffect(() => {
    setSelectedOutlet(Number(localStorage.getItem("company_id")));
    setIsChanged(false);
  }, [isChanged]);

  useEffect(() => {
    localStorage.getItem("roles")
      ? setUserRole(
          localStorage
            .getItem("roles")
            .split(",")
            .map((item) => Number(item))
        )
      : handleLogout();
  }, []);

  return (
    <div className="flex h-screen p-0 m-0 sticky top-0 z-[100] bg-white">
      <Sidebar
        breakPoint="lg"
        backgroundColor="#fff"
        className="shadow-md"
        width={SIDEBAR_WIDTH + "px"}
      >
        <div className="flex flex-column">
          <img
            src={Logo}
            alt="logo"
            className="mt-3 mb-10 ml-16 h-[7%] w-[25%]"
          />
          <Menu>
            {userRole.includes("2") ? null : (
              <MenuItem
                icon={<MdOutlineDashboard />}
                routerLink={<Link to="/home" />}
                active={window.location.pathname === "/home"}
              >
                Dashboard
              </MenuItem>
            )}
            {/* {userRole.includes(2) ? (
              <>
                <MenuItem
                  icon={<BsChatLeftText />}
                  routerLink={<Link to="/chat" />}
                  active={window.location.pathname === "/chat"}
                >
                  Chat
                </MenuItem>
                <MenuItem
                  icon={<MdOutlineReceiptLong />}
                  routerLink={<Link to="/orders" />}
                  active={window.location.pathname === "/orders"}
                >
                  Pesanan
                </MenuItem>
              </>
            ) : null} */}

            <div>
              <MenuItem
                icon={<BsCartPlus />}
                routerLink={<Link to="/purchase" />}
                active={window.location.pathname === "/purchase"}
                disabled
                // disabled={
                // statusUser?.status_code === 2 || statusUser?.status_code === 8
                // true
                // }
              >
                Purchase
              </MenuItem>

              {userRole.includes(9) ? (
                <SubMenu icon={<BiPurchaseTag />} label="Purchase Order">
                  <MenuItem
                    icon={<IoIosCreate />}
                    routerLink={<Link to="/purchase-order" />}
                    active={window.location.pathname === "/purchase-order"}
                  >
                    Create Purchase Order
                  </MenuItem>
                  <MenuItem
                    icon={<TbFileImport />}
                    routerLink={<Link to="/purchase-order/import" />}
                    active={
                      window.location.pathname === "/purchase-order/import"
                    }
                    disabled={
                      profileData && profileData !== undefined
                        ? !profileData.role?.includes(9)
                        : true
                    }
                  >
                    Import Purchase Order
                  </MenuItem>
                </SubMenu>
              ) : null}
              {userRole.some((elem) => INVENTORY_ROLE.includes(elem)) ? (
                <SubMenu icon={<MdOutlineInventory2 />} label="Inventory">
                  {userRole.includes(4) ? (
                    <MenuItem
                      routerLink={<Link to="/inventory/produk-aktif" />}
                      active={[
                        "/inventory/produk-aktif",
                        "/inventory/produk-habis",
                        "/inventory/produk-hold",
                        "/inventory/produk-expired",
                        "/inventory/produk-inactive",
                      ].some((link) => window.location.pathname === link)}
                    >
                      Inventory List
                    </MenuItem>
                  ) : null}
                  {userRole.includes(22) ? (
                    <MenuItem
                      routerLink={<Link to="/inventory/stock-adjustment" />}
                      active={
                        window.location.pathname ===
                        "/inventory/stock-adjustment"
                      }
                      // disabled
                    >
                      Penyesuaian Stock
                    </MenuItem>
                  ) : null}
                  {userRole.includes(23) ? (
                    <MenuItem
                      routerLink={<Link to="/inventory/stock-opname" />}
                      active={
                        window.location.pathname === "/inventory/stock-opname"
                      }
                      // disabled
                    >
                      Stock Opname
                    </MenuItem>
                  ) : null}
                  {userRole.includes(24) ? (
                    <MenuItem
                      routerLink={<Link to="/inventory/card-stock" />}
                      active={
                        window.location.pathname === "/inventory/card-stock"
                      }
                      // disabled
                    >
                      Kartu Stock
                    </MenuItem>
                  ) : null}
                  {userRole.includes(25) ? (
                    <MenuItem
                      routerLink={<Link to="/inventory/summary" />}
                      active={window.location.pathname === "/inventory/summary"}
                    >
                      Inventory Summary
                    </MenuItem>
                  ) : null}
                  {userRole.includes(45) ? (
                    <SubMenu label="Sinkronisasi Akun">
                      <MenuItem
                        routerLink={
                          <Link to="/inventory/sinkronisasi/good-doctor" />
                        }
                        active={
                          window.location.pathname ===
                          "/inventory/sinkronisasi/good-doctor"
                        }
                      >
                        Good Doctor
                      </MenuItem>
                    </SubMenu>
                  ) : null}
                </SubMenu>
              ) : null}

              {userRole.some((elem) => PATIENT_CARE_ROLE.includes(elem)) ? (
                <SubMenu icon={<CgUserList />} label="Patient Care">
                  {userRole.includes(17) ? (
                    <MenuItem
                      routerLink={<Link to="/patient-care/medical-record" />}
                      active={
                        window.location.pathname ===
                        "/patient-care/medical-record"
                      }
                    >
                      Patient Medical Record
                    </MenuItem>
                  ) : null}
                  {userRole.includes(20) ? (
                    <MenuItem
                      routerLink={<Link to="/patient-care/patient-database" />}
                      active={
                        window.location.pathname ===
                        "/patient-care/patient-database"
                      }
                    >
                      Patient Database
                    </MenuItem>
                  ) : null}
                  {userRole.includes(21) ? (
                    <MenuItem
                      routerLink={<Link to="/patient-care/doctor-database" />}
                      active={
                        window.location.pathname ===
                        "/patient-care/doctor-database"
                      }
                    >
                      Doctor Database
                    </MenuItem>
                  ) : null}
                  {userRole.includes(18) ? (
                    <MenuItem
                      routerLink={<Link to="/patient-care/initial-analysis" />}
                      active={
                        window.location.pathname ===
                        "/patient-care/initial-analysis"
                      }
                    >
                      Initial Analysis
                    </MenuItem>
                  ) : null}
                  {userRole.includes(19) ? (
                    <MenuItem
                      routerLink={<Link to="/patient-care/doctor-analysis" />}
                      active={
                        window.location.pathname ===
                        "/patient-care/doctor-analysis"
                      }
                    >
                      Doctor Analysis
                    </MenuItem>
                  ) : null}
                  {userRole.includes(16) ? (
                    <MenuItem
                      routerLink={<Link to="/patient-care/services" />}
                      active={
                        window.location.pathname === "/patient-care/services"
                      }
                    >
                      Services
                    </MenuItem>
                  ) : null}
                  {userRole.includes(26) ? (
                    <MenuItem
                      routerLink={
                        <Link to="/patient-care/prescription-history" />
                      }
                      active={
                        window.location.pathname ===
                        "/patient-care/prescription-history"
                      }
                    >
                      Prescription History
                    </MenuItem>
                  ) : null}
                </SubMenu>
              ) : null}

              {userRole.some((elem) => WAREHOUSE_ROLE.includes(elem)) &&
              dataOutlet &&
              dataOutlet.filter((item) => item.status === 1).length > 0 ? (
                <SubMenu icon={<BsBoxSeam />} label="Central Warehouse">
                  {userRole.includes(28) ? (
                    <MenuItem
                      routerLink={
                        <Link to="/central-warehouse/manual-billing/all-billing" />
                      }
                      active={[
                        "/central-warehouse/manual-billing/all-billing",
                        "/central-warehouse/manual-billing/unpaid",
                        "/central-warehouse/manual-billing/overdue",
                        "/central-warehouse/manual-billing/paid",
                        "/central-warehouse/manual-billing/draft",
                      ].some((link) => window.location.pathname === link)}
                    >
                      Billing
                    </MenuItem>
                  ) : null}
                  {userRole.includes(29) ? (
                    <MenuItem
                      routerLink={
                        <Link to="/central-warehouse/purchase-order" />
                      }
                      active={
                        window.location.pathname ===
                        "/central-warehouse/purchase-order"
                      }
                    >
                      Purchase Order
                    </MenuItem>
                  ) : null}
                  {userRole.some((elem) =>
                    WAREHOUSE_INVENTORY_ROLE.includes(elem)
                  ) ? (
                    <SubMenu label="Inventory">
                      {userRole.includes(27) ? (
                        <MenuItem
                          routerLink={
                            <Link to="/central-warehouse/inventory/produk-aktif" />
                          }
                          active={[
                            "/central-warehouse/inventory/produk-aktif",
                            "/central-warehouse/inventory/produk-habis",
                            "/central-warehouse/inventory/produk-hold",
                            "/central-warehouse/inventory/produk-expired",
                            "/central-warehouse/inventory/produk-inactive",
                          ].some((link) => window.location.pathname === link)}
                        >
                          Inventory List
                        </MenuItem>
                      ) : null}

                      {userRole.includes(30) ? (
                        <MenuItem
                          routerLink={
                            <Link to="/central-warehouse/inventory/card-stock" />
                          }
                          active={
                            window.location.pathname ===
                            "/central-warehouse/inventory/card-stock"
                          }
                        >
                          Kartu Stock
                        </MenuItem>
                      ) : null}
                      {userRole.includes(31) ? (
                        <MenuItem
                          routerLink={
                            <Link to="/central-warehouse/inventory/summary" />
                          }
                          active={
                            window.location.pathname ===
                            "/central-warehouse/inventory/summary"
                          }
                        >
                          Inventory Summary
                        </MenuItem>
                      ) : null}
                      {userRole.includes(32) ? (
                        <MenuItem
                          routerLink={
                            <Link to="/central-warehouse/inventory/stock-adjustment" />
                          }
                          active={
                            window.location.pathname ===
                            "/central-warehouse/inventory/stock-adjustment"
                          }
                        >
                          Penyesuaian Stok
                        </MenuItem>
                      ) : null}
                      {userRole.includes(33) ? (
                        <MenuItem
                          routerLink={
                            <Link to="/central-warehouse/inventory/stock-opname" />
                          }
                          active={
                            window.location.pathname ===
                            "/central-warehouse/inventory/stock-opname"
                          }
                        >
                          Stock Opname
                        </MenuItem>
                      ) : null}
                    </SubMenu>
                  ) : null}
                  {userRole.some((elem) =>
                    WAREHOUSE_INVOICE_ROLE.includes(elem)
                  ) ? (
                    <SubMenu label="Invoice">
                      {userRole.includes(41) ? (
                        <MenuItem
                          routerLink={
                            <Link to="/central-warehouse/invoice/customer" />
                          }
                          active={
                            window.location.pathname ===
                            "/central-warehouse/invoice/customer"
                          }
                          // disabled={statusUser?.status_code === 9}
                        >
                          Customer
                        </MenuItem>
                      ) : null}
                      {userRole.includes(42) ? (
                        <MenuItem
                          routerLink={
                            <Link to="/central-warehouse/invoice/invoice" />
                          }
                          active={
                            window.location.pathname ===
                            "/central-warehouse/invoice/invoice"
                          }
                        >
                          Invoice
                        </MenuItem>
                      ) : null}
                      {userRole.includes(43) ? (
                        <MenuItem
                          routerLink={
                            <Link to="/central-warehouse/invoice/promo" />
                          }
                          active={
                            window.location.pathname ===
                            "/central-warehouse/invoice/promo"
                          }
                        >
                          Setting Promo
                        </MenuItem>
                      ) : null}
                      {userRole.includes(44) ? (
                        <MenuItem
                          routerLink={
                            <Link to="/central-warehouse/invoice/setting-invoice" />
                          }
                          active={
                            window.location.pathname ===
                            "/central-warehouse/invoice/setting-invoice"
                          }
                        >
                          Setting Invoice
                        </MenuItem>
                      ) : null}
                    </SubMenu>
                  ) : null}
                  {userRole.includes(34) ? (
                    <MenuItem
                      routerLink={<Link to="/central-warehouse/allocation" />}
                      active={
                        window.location.pathname ===
                        "/central-warehouse/allocation"
                      }
                    >
                      Alokasi
                    </MenuItem>
                  ) : null}
                  {userRole.includes(35) ? (
                    <MenuItem
                      routerLink={<Link to="/central-warehouse/transfer" />}
                      active={
                        window.location.pathname ===
                        "/central-warehouse/transfer"
                      }
                    >
                      Transfer
                    </MenuItem>
                  ) : null}
                  {userRole.includes(36) ? (
                    <MenuItem
                      routerLink={
                        <Link to="/central-warehouse/retur-warehouse" />
                      }
                      active={
                        window.location.pathname ===
                        "/central-warehouse/retur-warehouse"
                      }
                    >
                      Retur to Warehouse
                    </MenuItem>
                  ) : null}
                  {userRole.includes(39) ? (
                    <MenuItem
                      routerLink={
                        <Link to="/central-warehouse/inbox/warehouse" />
                      }
                      active={
                        window.location.pathname ===
                        "/central-warehouse/inbox/warehouse"
                      }
                    >
                      Inbox Warehouse
                    </MenuItem>
                  ) : null}
                  {userRole.includes(40) ? (
                    <MenuItem
                      routerLink={<Link to="/central-warehouse/inbox/outlet" />}
                      active={
                        window.location.pathname ===
                        "/central-warehouse/inbox/outlet"
                      }
                    >
                      Inbox Outlet
                    </MenuItem>
                  ) : null}
                  {userRole.includes(37) ? (
                    <MenuItem
                      routerLink={
                        <Link to="/central-warehouse/request-allocation" />
                      }
                      active={
                        window.location.pathname ===
                        "/central-warehouse/request-allocation"
                      }
                    >
                      Request Allocation
                    </MenuItem>
                  ) : null}
                  {userRole.includes(38) ? (
                    <MenuItem
                      routerLink={
                        <Link to="/central-warehouse/request-transfer" />
                      }
                      active={
                        window.location.pathname ===
                        "/central-warehouse/request-transfer"
                      }
                    >
                      Request Transfer
                    </MenuItem>
                  ) : null}
                </SubMenu>
              ) : null}

              {userRole.includes(8) ? (
                <MenuItem
                  icon={<FaHandHoldingUsd />}
                  routerLink={<Link to="/inventory/consignment" />}
                  active={window.location.pathname === "/inventory/consignment"}
                  // disabled={true}
                >
                  Consignment
                </MenuItem>
              ) : null}

              {userRole.includes(7) ? (
                <SubMenu icon={<GrNotes />} label="Billing">
                  <MenuItem
                    routerLink={<Link to="/billing/unpaid" />}
                    active={window.location.pathname === "/billing/unpaid"}
                    // disabled={statusUser?.status_code === 9}
                    disabled={true}
                  >
                    Billing List
                  </MenuItem>
                  <MenuItem
                    routerLink={<Link to="/manual-billing" />}
                    active={window.location.pathname === "/manual-billing"}
                    // disabled
                  >
                    Manual Billing
                  </MenuItem>
                </SubMenu>
              ) : null}

              {userRole.some((elem) => RETUR_ROLE.includes(elem)) ? (
                <SubMenu icon={<RiArrowGoBackLine />} label="Retur">
                  {userRole.includes(46) ? (
                    <MenuItem
                      routerLink={<Link to="/retur/buy-retur" />}
                      active={window.location.pathname === "/retur/buy-retur"}
                    >
                      Retur Pembelian
                    </MenuItem>
                  ) : null}
                  {userRole.includes(47) ? (
                    <MenuItem
                      routerLink={<Link to="/retur/sell-retur" />}
                      active={window.location.pathname === "/retur/sell-retur"}
                    >
                      Retur Penjualan
                    </MenuItem>
                  ) : null}
                </SubMenu>
              ) : null}

              {userRole.includes(5) ? (
                <SubMenu icon={<RiFileChartLine />} label="Reporting">
                  <MenuItem
                    routerLink={<Link to="/reporting" />}
                    active={window.location.pathname === "/reporting"}
                  >
                    All Report
                  </MenuItem>
                  <MenuItem
                    routerLink={<Link to="/reporting/product" />}
                    active={window.location.pathname === "/reporting/product"}
                  >
                    Report Product
                  </MenuItem>
                  <MenuItem
                    routerLink={<Link to="/reporting/log-shift" />}
                    active={window.location.pathname === "/reporting/log-shift"}
                  >
                    Log Shift
                  </MenuItem>
                  <MenuItem
                    routerLink={<Link to="/operational/list" />}
                    active={window.location.pathname === "/operational/list"}
                  >
                    Operational Cost
                  </MenuItem>
                  <MenuItem
                    routerLink={<Link to="/reporting/profit-loss" />}
                    active={
                      window.location.pathname === "/reporting/profit-loss"
                    }
                  >
                    Profit & Loss
                  </MenuItem>
                </SubMenu>
              ) : null}

              {userRole.some((elem) => INVOICE_ROLE.includes(elem)) ? (
                <SubMenu icon={<FaFileInvoiceDollar />} label="Invoice">
                  {userRole.includes(10) ? (
                    <MenuItem
                      routerLink={<Link to="/invoice/customer" />}
                      active={window.location.pathname === "/invoice/customer"}
                    >
                      Customer
                    </MenuItem>
                  ) : null}
                  {userRole.includes(11) ? (
                    <MenuItem
                      routerLink={<Link to="/invoice/invoice" />}
                      active={window.location.pathname === "/invoice/invoice"}
                    >
                      Invoice
                    </MenuItem>
                  ) : null}
                  {userRole.includes(12) ? (
                    <MenuItem
                      routerLink={<Link to="/invoice/promo" />}
                      active={window.location.pathname === "/invoice/promo"}
                    >
                      Setting Promo
                    </MenuItem>
                  ) : null}
                  {userRole.includes(13) ? (
                    <MenuItem
                      routerLink={<Link to="/invoice/setting-invoice" />}
                      active={
                        window.location.pathname === "/invoice/setting-invoice"
                      }
                    >
                      Setting Invoice
                    </MenuItem>
                  ) : null}
                </SubMenu>
              ) : null}

              <MenuItem
                icon={<TbDiscount />}
                routerLink={<Link to="/discount" />}
                active={window.location.pathname === "/discount"}
              >
                Discount/Promo
              </MenuItem>
              <MenuItem
                icon={<BsPeopleFill />}
                routerLink={<Link to="/pelanggan" />}
                active={window.location.pathname === "/pelanggan"}
              >
                Pelanggan
              </MenuItem>
              <MenuItem
                icon={<MdBusinessCenter />}
                routerLink={<Link to="/sales" />}
                active={window.location.pathname === "/sales"}
              >
                Sales
              </MenuItem>
              {userRole.includes(6) ? (
                <MenuItem
                  icon={<FaUserCog />}
                  routerLink={<Link to="/user-management" />}
                  active={window.location.pathname === "/user-management"}
                  // disabled
                >
                  User Management
                </MenuItem>
              ) : null}
              {/* </Link> */}
              <SubMenu icon={<FiSettings />} label="Settings">
                <MenuItem
                  routerLink={<Link to="/settings/payment-method" />}
                  active={
                    window.location.pathname === "/settings/payment-method"
                  }
                >
                  Payment method
                </MenuItem>
                <MenuItem
                  routerLink={<Link to="/settings/invoice-setting" />}
                  active={
                    window.location.pathname === "/settings/invoice-setting"
                  }
                >
                  Invoice Settings
                </MenuItem>
              </SubMenu>
            </div>
          </Menu>
          <div className="grid grid-cols-5 p-6 w-full gap-2">
            <div className="w-fit flex items-center">
              <img
                src={img_profile}
                alt="profile"
                className="w-10 rounded-full"
              />
            </div>
            <div className="break-all col-span-3">
              <div
                className="flex flex-col text-[12px] hover:cursor-pointer hover:text-[#0d6efd]"
                onClick={() => navigate("/profile")}
              >
                <p className="font-bold">{name}</p>
                <p>{job}</p>
              </div>
              {Number(titleId) === 1 && dataOutlet && dataOutlet.length > 1 ? (
                //&&  !dataOutlet.every((outlet) => outlet.status !== 1) ? (
                <div>
                  <select
                    className="py-2 hover:text-gray-400 hover:cursor-pointer text-[12px] w-full whitespace-nowrap text-ellipsis"
                    onChange={(e) => {
                      setSelectedOutlet(e.target.value);
                    }}
                    value={Number(localStorage.getItem("company_id"))}
                  >
                    {dataOutlet && dataOutlet.length > 1 ? (
                      dataOutlet.map((outlet) => {
                        // if (outlet.status === 1)
                        return (
                          <option
                            value={outlet.company_id}
                            key={outlet.company_id}
                          >
                            {outlet.company_name}
                          </option>
                        );
                      })
                    ) : (
                      <option value={0} disabled>
                        Tidak ada outlet Lain
                      </option>
                    )}
                  </select>
                </div>
              ) : null}
            </div>
            <div className="flex items-center">
              <Tooltip title="Logout">
                <div
                  className="ml-[10px] hover:cursor-pointer hover:text-[#0d6efd]"
                  onClick={handleLogout}
                >
                  <FiLogOut className="text-[30px]" />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </Sidebar>

      <SwitchOutletModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        setIsChanged={setIsChanged}
        companyId={selectedOutlet}
        outletName={selectedOutletName}
      />
    </div>
  );
};

export default SidebarComponents;
